<template>
    <div class="notes_container">
        <p class="text-ibm-plex-sans loading" v-if="loading">
          {{ $t('Loading') }}
          <font-awesome-icon icon="spinner" class="fa-lg" spin />
        </p>
        <div class="notes_header">
          <Button id="notesShowBtn" :label="$t('alter_tag_notes')" :color="'primary'" @click="showModal()"/>
        </div>
        <div class="notes-data-container" v-for="note of notes" :key="note.id">
            <div class="note-data">
                <div class="action-taken">{{note.categoryToDisplay}} {{ note.actionTaken }}</div>
                <div class="comments"> {{ note.comments }}</div>
                <div class="user-desc dark-text">Posted by: {{ note.userDescription}} </div>
                <div class="user-desc dark-text"> on: {{ getFormattedDate(note.timestamp)}}  {{timeZoneAbbreviation}} </div>
            </div>
        </div>
        <div v-if="addingNote">
            <Modal
            :headingText="$t('alter_tag_notes')"
            v-show="showModal"
            @close="closeModal"
            @click="addNote"
            >
            <div slot="body">
                <Select class="tagsSelect" name="tagsSelect" :label="$t('select_patient_tags')" v-model="tags" :allOptions="tagsOptionsWithTranslations"  :is-multi-select="true" :orientation="'v'"/>
                <TextArea class="note-input" name="noteInput" :label="$t('notes')" v-model="comments" :orientation="'v'" :placeholderText="$t('type_your_note_here')"/>
            </div>
            </Modal>
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex'
import Button from '@/app/shared/components/Button'
import Modal from '../../../../shared/components/Modal.vue'
import Select from '../../../../shared/components/Select.vue'
import TextArea from '@/app/shared/components/TextArea'

export default {
  name: 'Notes',
  components: {
    Button,
    Modal,
    Select,
    TextArea
  },
  data () {
    return {
      loading: true,
      addingNote: false,
      notes: [],
      tagsOptions: [],
      tags: [],
      comments: '',
      timeZoneAbbreviation: null
    }
  },
  props: {
    patientId: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions('Notes', ['getPatientNotes', 'addTagsAndNote']),
    async showModal () {
      this.addingNote = true
    },
    async addNote () {
      this.addingNote = false
      this.loading = true
      var patientId = this.$route.params.patientId
      await this.addTagsAndNote(this.getTagsAndNotesPayload())
      const response = (await this.getPatientNotes(patientId)).data
      this.notes = response.notes
      this.tags = this.currentTagsWithTranslations(response.tags)
      this.tagsOptions = response.tagsOptions
      this.timeZoneAbbreviation = response.timeZoneAbbreviation
      this.comments = ''
      this.loading = false
    },
    getTagsAndNotesPayload () {
      const tagsAndNotesPayLoadData = {
        payload: {
          episodeId: this.$route.params.patientId,
          episodeTags: this.tags.map(tag => ({ key: tag.Key, value: tag.Value, date: null })),
          deleteExistingTags: true,
          sticky: true,
          comment: this.comments,
          actionTaken: this.getActionTaken(this.tags),
          logCategory: 'Regular_Note_and_Current_tags',
          addTagsDateInActionTaken: false
        }
      }
      return tagsAndNotesPayLoadData
    },
    getFormattedDate (strDate) {
      const date = new Date(strDate)
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    },
    closeModal () {
      this.addingNote = false
    },
    formatTagOption (tag) {
      return { Key: this.$t(tag.tagName), Value: this.$t(tag.tagGroup) + ': ' + this.$t('tags_' + tag.tagName) }
    },
    formatTag (tag) {
      return { Key: this.$t(tag.tagName), Value: this.$t('tags_' + tag.tagName) + '(' + this.$t(tag.tagGroup) + ')' }
    },
    currentTagsWithTranslations (tags) {
      return tags.map(tag => this.formatTag(tag))
    },
    getActionTaken (tags) {
      let actionTaken = ''

      if (tags.length > 0) {
        actionTaken = 'Tags set: ' + tags.map(tag => this.$t('tags_' + tag.Key)).join(',')
      }

      return actionTaken
    }
  },
  async mounted () {
    const response = (await this.getPatientNotes(this.$route.params.patientId)).data
    this.notes = response.notes
    this.tags = this.currentTagsWithTranslations(response.tags)
    this.tagsOptions = response.tagsOptions
    this.timeZoneAbbreviation = response.timeZoneAbbreviation
    this.loading = false
  },
  computed: {
    tagsOptionsWithTranslations () {
      return this.tagsOptions.map(tag => this.formatTagOption(tag))
    }
  }
}
</script>

<style lang="scss" scoped>
.notes_container {
  background-color: var(--background-color-primary-light-dark);
  padding: 10px;
}
.notes_header {
  display:flex;
  justify-content: flex-end;
  margin: 10px 0 20px 0;
  align-items: center;
}

.notes-data-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 4px;
  margin-bottom: 10px;
}

.note-data {
  background: var(--notes-box-color);
  border: 1px solid var(--input-border);
  box-sizing: border-box;
  border-radius: 8px;
}

.action-taken {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0125em;
  color: var(--text-primary-color);
  margin: 10px 0px 10px 10px;
  width: 70%;
}

.comments {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0125em;
  display: flex;
  color: var(--text-primary-color);
  margin-left: 10px;
  width: 70%;
}

.user-desc {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 183%;
  text-align: right;
  letter-spacing: 0.015em;
  margin-right: 10px;
}

.loading {
  margin-top: 10px !important;
  text-align: center;
}

</style>
